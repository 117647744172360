<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('grade_entry')" :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('grade_entry')" :isFilter="false"/>
            </template>
            <div>
                <div class="d-flex flex-column flex-lg-row full-height overflow-hidden">
                    <div class="width-300 mr-3 d-none d-lg-block" style="min-width: 300px;">
                        <div class="courses d-flex flex-column">
                            <h5>{{ $t('courses') }}</h5>
                            <div class="filter">
                                <b-form-input v-model="courseFilter" size="lg" :placeholder="$t('filter')"/>
                            </div>
                            <div class="flex-grow-1 course-list" v-if="isInstructorCoursesList">
                                <loading :show="isInstructorCoursesList"/>
                            </div>
                            <div class="flex-grow-1 course-list d-none d-lg-block" v-else>
                                <div class="text-center" v-if="filteredCourses.length == 0">
                                    {{ $t('no_result_2') }}
                                </div>
                                <div class="course cursor-pointer"
                                     :class="selectedCourse === item.section_id ? 'selected-item' : ''"
                                     v-for="(item, key) in filteredCourses" :id="'course-' + item.section_id"
                                     :ref="'course-' + item.section_id" :key="key" @click="() => {
                                        selectedCourse = item.section_id
                                        getStudentCourseEntry(item)
                                    }">
                                    <div class="name">
                                        {{ item.course_name }}
                                    </div>
                                    <div class="instructor">{{ item.instructor }}</div>
                                    <div class="other">
                                        <b-badge variant="dark">{{ item.course_code }}</b-badge>
                                        <b-badge variant="dark" v-if="item.section">Section: {{ item.section }}</b-badge>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <div class="h-100 d-flex flex-column">
                            <div class="mb-3 d-block d-lg-none">
                                <h5>{{ $t('sections') }}</h5>
                                <b-form-select :options="courseOptions" v-model="selectedCourseID"
                                               @input="selectCourse"/>
                            </div>
                            <h5>{{ $t('students') }}</h5>
                            <div class="students d-flex flex-column">
                                <b-alert v-if="checkGradeEntry && students.length" class="d-flex align-items-center py-3 px-4 mb-3" show variant="info" style="border-radius: 10px;">
                                    <i class="ri-information-fill mr-3"></i>
                                    <div class="d-flex flex-column" v-html="$t('add_note_active_information')"></div>
                                </b-alert>
                                <b-alert v-else-if="!checkGradeEntry && students.length === 0" class="d-flex align-items-center py-3 px-4 mb-3" show variant="info" style="border-radius: 10px;">
                                    <i class="ri-information-fill mr-3"></i>
                                    <div class="d-flex flex-column" v-html="$t('add_note_passive_information')"></div>
                                </b-alert>

                                <div class="flex-grow-1 week-list" v-if="isTable">
                                    <loading :show="isTable"/>
                                </div>
                                <div v-else>
                                    <div class="text-center" v-if="students.length == 0">
                                        {{ $t('no_result_2') }}
                                    </div>
                                    <div v-else>
                                        <b-table :empty-filtered-text="this.$t('table_not_found')"
                                                 :empty-text="this.$t('table_is_empty')" bordered striped responsive
                                                 :items="students" :fields="fieldsStudent"
                                                 class="mb-0 table-dropdown no-scrollbar border rounded">
                                            <template #cell(exams)="row">
                                                <b-form-select
                                                    @change="changeGradeEvent(students[row.item.student_program_id].instructors_letter_grade,row.item)"
                                                    v-model="students[row.item.student_program_id].instructors_letter_grade"
                                                    :options="grades(row.item.grades)"></b-form-select>
                                            </template>
                                            <template #cell(note)="row">
                                                <b-button class="rounded-circle border-0 bg-light"
                                                          :class="[row.item.instructors_note === null ? 'text-secondary' : 'text-primary']"
                                                          @click="handleInfo(row.item)"
                                                          style="width: 30px; height: 30px">
                                                    <b-icon icon="info-circle"></b-icon>
                                                </b-button>
                                            </template>
                                        </b-table>
                                        <div class="d-flex mt-4">
                                            <b-button variant="primary" class="mr-2" @click="checkNotes">
                                                {{ $t("save") }}
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <b-modal ref="saveModal" id="saveModal" :size="size" centered :title="$t('warning')">
                        <template>
                            <p class="my-4">
                                Clicking confirm will submit the final grades. After the {{endDate}}, only the Registrar will be able to make any changes to the final grades.
                            </p>
                        </template>
                        <template #modal-footer>
                            <div class="w-100">
                                <b-button variant="primary" size="sm" class="float-right" label="confirmation"
                                          @click="save"/>
                            </div>
                        </template>
                    </b-modal>
                    <CommonModal ref="errorDetailModal" id="errorDetailModal"  :onHideOnlyX="true" @bHideModalHeaderClose="clearNote()" >
                        <template v-slot:CommonModalTitle>
                            {{$t('error')}}
                        </template>
                        <template v-slot:CommonModalContent>
                            <div v-for="(student, index) in studentErrors" :key="'student-' + index" class="w-100">
                                <p>{{
                                        $t('student_grade_entry_failed', {
                                            number: student.number, name:
                                            student.name, surname: student.surname
                                        })
                                    }}</p>
                                <p>{{ $t('reason') }}: {{ $t(student.message) }}</p>
                            </div>
                        </template>
                    </CommonModal>
                </div>
            </div>
            <ValidationObserver ref="formNoteModalValidate">
                <CommonModal ref="noteModal" id="noteModal" :onHideOnlyX="true" @bHideModalHeaderClose="clearNote()">
                    <template v-slot:CommonModalTitle>
                        {{ $t('enter_explanation') }}
                    </template>
                    <template v-slot:CommonModalContent>
                        <div class="mb-2" v-if="student">
                            <b-row>
                                <b-col cols="6"> {{ $t('student_name') }}:</b-col>
                                <b-col cols="6">{{ student.name }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6"> {{ $t('student_surname') }}:</b-col>
                                <b-col cols="6">{{ student.surname }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6"> {{ $t('student_number') }}:</b-col>
                                <b-col cols="6">{{ student.student_number }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6"> {{ $t('letter_grade') }}:</b-col>
                                <b-col cols="6">{{ student.instructors_letter_grade }}</b-col>
                            </b-row>
                            <template
                                v-if="['IP'].includes(student.instructors_letter_grade)"
                            >
                                <p class="mt-5 mb-0 font-weight-bold">{{ $t('warning') }}</p>
                                <p class="mb-2">
                                    {{ $t('grade_entry_ip_info') }}
                                </p>
                                <button type="button" class="btn btn-success" @click="$bvModal.hide('noteModal')">Confirm</button>
                            </template>
                            <ValidationProvider name="note" rules="required" v-slot="{ valid, errors }">
                                <b-form-textarea
                                    v-if="['F','U'].includes(student.instructors_letter_grade)"
                                    v-model="student_note"
                                    id="textarea"
                                    :placeholder="$t('explanation')"
                                    rows="3" max-rows="6" @input="handleInfoKeyup($event)"
                                    :state="errors[0] ? false : null"
                                    class="mt-2"

                                ></b-form-textarea>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </ValidationProvider>
                            <div class="w-100 mt-2">
                                <processing-button variant="primary" size="sm" cssClass="float-right" label="save"
                                                   :processing="loading" @click="handleInfoSave"
                                                   v-if="['F','U'].includes(student.instructors_letter_grade)"/>
                            </div>
                        </div>
                    </template>
                </CommonModal>
            </ValidationObserver>
        </app-layout>

    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate"
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import StudentCourseService from '@/services/StudentCourseService'
import SectionService from '@/services/SectionService'
import Loading from '@/components/elements/Loading2'
import ProcessingButton from '@/components/elements/ProcessingButton'
import CommonModal from "@/components/elements/CommonModal";


export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        AppLayout,
        Header,
        HeaderMobile,
        Loading,
        ProcessingButton,
        CommonModal
    },
    metaInfo() {
        return {
            title: this.$t("grade_entry")
        }
    },

    data() {
        return {
            courseOptions: [],
            courseFilter: '',
            instructorCoursesList: [],
            isInstructorCoursesList: false,
            current_course: {},
            selectedCourseID: null,
            selectedCourse: null,
            loading: false,
            endDate:null,
            fieldsStudent: [
                {
                    key: "student_number",
                    label: this.toUpperCase("student_number"),
                    sortable: true,
                },
                {
                    key: "name",
                    label: this.toUpperCase("name_surname"),
                    sortable: true,
                    formatter: (value, key, item) => {
                        return item.name + " " + item.surname;
                    },
                },
                {
                    key: ("class_name"),
                    label: this.toUpperCase("class"),
                    sortable: true
                },
                {
                    key: "exams",
                    label: this.$t("grade"),
                    sortable: false,
                    thClass: 'width-100'
                },
                {
                    key: "note",
                    label: '',
                    sortable: false,
                    thClass: 'width-50'
                },
            ],
            students: [],
            student: null,
            oldStudents: [],
            studentErrors: [],
            isStudents: false,
            isTable: "",
            size: "md",
            student_note: "",
            current_student: "",
            checkGradeEntry: true
        }
    },
    methods: {
        showModal() {
            this.$refs.noteModal.$refs.commonModal.show()

        },
        hideModal() {
            this.$refs.noteModal.$refs.commonModal.hide()
        },
        clearNote() {
            if (['F', 'U'].includes(this.student.instructors_letter_grade) && this.student.instructors_note == null) {
                this.student.instructors_letter_grade = null
            }
        },
        grades(options) {

            let data = []
            options.map((item) => {
                data.push({value: item, text: item});
            })
            let optionData = [{value: null, text: this.$t("select")}, ...data]
            return optionData
        },
        changeGradeEvent(value, student) {
            if (value == 'IP') {
                this.showModal("noteModal")
            }
            if (value == 'U' || value == 'F') {
                this.showModal("noteModal")
            }
            this.student = student
            this.student_note = student.instructors_note
        },
        handleInfo(student) {
            this.student = student
            this.student_note = student.instructors_note
            this.showModal("noteModal")
        },
        handleInfoKeyup(value) {
            this.student_note = value
        },
        async handleInfoSave() {
            const valid = await this.$refs['formNoteModalValidate'].validate()
            if (!valid) {
                return
            }

            this.students[this.student.student_program_id].instructors_note = this.student_note
            this.hideModal("noteModal")
            // this.checkNotes()
        },
        getInstructorCourses() {
            this.isInstructorCoursesList = true
            return SectionService.instructorSections()
                .then((response) => {
                    if (response.data.success) {
                        let data = response.data.data
                        this.isInstructorCoursesList = false
                        this.instructorCoursesList = data
                        this.setCourseOptions(data)
                    }
                })
                .catch(err => this.showErrors(err))
        },
        setCourseOptions(data) {
            let setDefaultValue = function (item) {
                this.getStudentCourseEntry(item)
            }

            if (data && data.length) {
                this.courseOptions = data.map(item => {
                    if (this.$route.params.sectionId == item.section_id) {
                        this.$on('doneCoursesList', () => {
                            setDefaultValue.call(this, item)
                        })
                    }

                    return {
                        value: item.section_id,
                        text: this.getObjectValue(item, 'course_code') + ' - ' + item.course_name + ' - Section:' + item.section
                    }
                })
            }
        },
        getStudentCourseEntry(course) {
            this.isTable = null
            this.current_course = course

            StudentCourseService.getEntry(course.section_id)
                .then((response) => {
                    if (response.data.success) {
                        this.isTable = true
                        this.checkGradeEntry = true
                        let student = []

                        response.data.data.student_courses.map((item) => {
                            student[item.student_program_id] = {...item}
                        })

                        this.endDate=response.data.data.end_date;
                        this.students = student
                        this.oldStudents = JSON.parse(JSON.stringify(student))
                    }
                })
                .catch(err => {
                    this.showErrors(err)
                    if(err.data.message === 'GRADE_ENTRY_NOT_ACTIVE') {
                        this.checkGradeEntry = false
                    }
                    this.students = []
                })
                .finally(() => this.isTable = null)
        },
        checkNotes() {
            const requireNoteStudents = this.students.filter(item => {
                return ["U", "F"].includes(item.instructors_letter_grade) && !item.instructors_note
                }
            )
            if (requireNoteStudents.length > 0) {
                this.handleInfo(requireNoteStudents[0])
                return false;
            }

            if(this.save(true)){
                this.$bvModal.show('saveModal');
            }
        },
        save(check=false) {
            if (this.checkPermission('studentcourse_updateentry')) {
                // this.loading = true
                let grades = []
                let emptyGrades=[]

                this.students.forEach((item, index) => {
                    if(!item.instructors_letter_grade || item.instructors_letter_grade=='CE'){
                        emptyGrades.push(item.student_program_id);
                    }
                    if (item.instructors_letter_grade != this.oldStudents[index]['instructors_letter_grade'] || item.instructors_note != this.oldStudents[index]['instructors_note']) {
                        grades.push({
                            student_program_id: item.student_program_id,
                            instructors_letter_grade: item.instructors_letter_grade,
                            instructors_note: item.instructors_note
                        })
                    }
                })

                if (emptyGrades.length > 0) {
                    this.$toast.error("Please, enter all grades")
                    this.$bvModal.hide("saveModal")
                    return false
                }

                if (grades.length == 0) {
                    this.$toast.error(this.$t("you_must_do_change"))
                    this.$bvModal.hide("saveModal")
                    return false
                }

                if(check){
                    return true;
                }

                let data = {
                    grades: grades
                }

                return StudentCourseService.updateEntry(this.current_course.section_id, data)
                    .then((res) => {
                        this.loading = false
                        this.$bvModal.hide("saveModal")
                        this.showMessage(res)
                        this.getStudentCourseEntry(this.current_course)

                        const response = res.data.data

                        if (response.failed_count > 0) {
                            this.studentErrors = []
                            response.failed_student_program_ids.forEach((item) => {
                                const student = this.students[item.student_program_id]
                                this.studentErrors.push({
                                    number: student.student_number,
                                    name: student.name,
                                    surname: student.surname,
                                    message: item.reason
                                })
                            })
                            this.showModal('errorDetailModal')
                        }
                    })
                    .catch(err => {
                        this.showErrors(err)
                        this.loading = false
                    })
            } else {
                this.$toast.error(this.$t("you_are_not_authorized_for_this_operation"))
            }
        },
        selectCourse(e) {
            let course = this.instructorCoursesList.find(item => item.section_id == e)

            if (course) {
                this.getStudentCourseEntry(course)
            }
        }
    },
    async created() {
        await this.getInstructorCourses()
        this.$emit('doneCoursesList')
    },
    mounted() {
        if (this.$route.params.courseId) {
            this.$on('doneCoursesList', () => {
                let course = this.$refs['course-' + this.$route.params.courseId]
                course[0].click()

                let courseListTop = document.getElementsByClassName('course-list')[0].offsetTop
                let courseTop = document.getElementById('course-' + this.$route.params.courseId).offsetTop
                document.getElementsByClassName('course-list')[0].scrollTop = courseTop - (courseListTop + 20)
            })
        }
    },
    computed: {
        filteredCourses() {
            if (this.courseFilter) {
                return this.instructorCoursesList.filter(item => {
                    let courseCodeValue, courseNameValue, courseNameEnValue = ''

                    if (item.course_code) courseCodeValue = item.course_code.toUpperCase().replaceAll(/[İi]/ig, 'I')
                    if (item.course_name) courseNameValue = item.course_name.toUpperCase().replaceAll(/[İi]/ig, 'I')

                    let filterValue = this.courseFilter.toUpperCase().replaceAll(/[İi]/ig, 'I')

                    return courseCodeValue.indexOf(filterValue) > -1 || courseNameValue.indexOf(filterValue) > -1 || courseNameEnValue.indexOf(filterValue) > -1
                })
            } else {
                return this.instructorCoursesList
            }
        }
    }
}
</script>

<style scoped>
.courses {
    height: 100%;
}

.courses .filter {
    margin-bottom: 5px;
}

.courses .filter input {
    border-radius: 5px;
}

.courses .course-list {
    border: 1px solid #e9ecef;
    background: #f9f9f9;
    padding: 18px 15px;
    border-radius: 4px;
    overflow-y: auto;
}

.courses .course-list .course {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100px;
    border: 1px solid #e9ecef;
    padding: 15px 15px 10px 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    background: #fff;
}

.selected-item {
    background: #00aeef !important;
    color: #fff !important;
}

.courses .course-list .course .name {
    font-size: 15px;
    line-height: 16px;
}

.courses .course-list .course .instructor {
    font-size: 10px;
    line-height: 16px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.courses .course-list .course .other {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.courses .course-list .course .other .badge {
    border: 1px solid #e9ecef;
    background: #f1f1f1;
    padding: 4px 6px;
    border-radius: 2px;
    font-weight: 500;
}

/*  Weeks */
.weeks {
    height: 100%;
}

.weeks .week-list {
    border: 1px solid #e9ecef;
    background: #f9f9f9;
    padding: 18px 15px;
    border-radius: 4px;
    overflow-y: auto;
}

.weeks .week-list .week {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #e9ecef;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    background: #fff;
}

.weeks .week-list .week span:first-child {
    font-size: 16px;
    margin-bottom: 2px;
}

.weeks .week-list .week span:last-child {
    font-size: 14px;
}

/* Students */
.students {
    height: 100%;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    padding: 18px 15px;
    overflow-y: auto;
}
</style>
